<script setup lang="ts">
import type { ComputedRef } from 'vue'
import type {
  IManagerTableEmits,
  IManagerTableProps,
} from '@manager/components/Group/Table/types'
import { type INodeObject, joinPath, parseSizeToGrid } from '@manager'
import type { RecurringChargesReviewTable } from './types'
import { useTable } from './composables/useTable'

const props = withDefaults(defineProps<IManagerTableProps>(), {
  parentValue: () => ({}),
  parentPath: '',
})
const emit = defineEmits<IManagerTableEmits>()

const colSize = computed(() => parseSizeToGrid(props.node.size))

const objectNode = props.node.nodes[0] as INodeObject
const objectPath = joinPath(props.parentPath, objectNode.name)

const [_, dataModelValue] = useObjectVModel(
  {
    ...props,
    node: objectNode,
  },
  'parentValue',
  emit,
)

const { dateFormat: leaseDateFormat } = useCurrentLease()

const { filteredData, leaseStartDate, leaseEndDate } = useTable(
  dataModelValue as ComputedRef<RecurringChargesReviewTable[]>,
  objectPath,
)
</script>

<template>
  <div
    :class="[colSize, 'grid grid-flow-row grid-cols-1 gap-2']"
    :data-path="objectPath"
  >
    <ReviewFormRentReviewTable
      :data="filteredData"
      :expiry-date="leaseEndDate"
      :commencement-date="leaseStartDate"
      :lease-date-format="leaseDateFormat"
      show-method
      show-amount
      :can-add="false"
      :can-edit="false"
      :can-delete="false"
      utc
    />
  </div>
</template>
